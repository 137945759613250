/*
 *
 *  remotes
 *
 */

import { buildQuery, getDefaultParams } from 'helpers/tools';
import { API } from 'aws-amplify';
const constantName = 'scenario';

function getScenarios(params) {
  const queryParams = params || { temp_type: 'Source' };
  return API.get('gf-service', `${constantName}`, getDefaultParams(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function getScenario(scenarioId) {
  return API.get('gf-service', `${constantName}/${scenarioId}`, getDefaultParams())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function createSourceDestination(payload) {
  return API.post('gf-service', 'addsourcedestination', getDefaultParams( {body: payload} ))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadRecords(params) {
  params = {...params} 
  const queryParams = buildQuery(params)
  return API.get('gf-service', 'getsourcedestination', getDefaultParams(null, { queryStringParameters: queryParams }))
      .then((response) => response)
      .catch((error) => Promise.reject(error));
}

function deleteScenario(payload){
  return API.del('gf-service', '/deleteresources/sourcedestination', getDefaultParams({body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const scenarioService = {
  getScenarios,
  getScenario,
  createSourceDestination,
  loadRecords,
  deleteScenario
};
