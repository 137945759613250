import { dialogContants } from '../_constants';

export const dialogActions = {
    request,
    success,
    error,
    clear
};
function request(progress) {
    return { type: dialogContants.REQUEST, progress };
}

function success(progress) {
    return { type: dialogContants.SUCCESS, progress };
}

function error() {
    return { type: dialogContants.ERROR };
}

function clear() {
    return { type: dialogContants.CLEAR };
}