// material-ui 
import { Stack, Typography, Avatar, Tooltip } from '@mui/material';
import LogoutIcon from 'assets/icons/logout.svg'; 
import { useDispatch } from 'store';
import { userActions } from 'store/actions';
import { useNavigate } from 'react-router';

const LogoutContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(userActions.logout(navigate));
  };

  const maxLength = 22;
  const email = localStorage.getItem('email');
  const userName = localStorage.getItem('fullname');

  const renderTooltip = (text) => {
    if (text && text.length > maxLength) {
      const truncatedText = text.substring(0, maxLength) + '...';
      return (
        <Tooltip title={text} arrow style={{ cursor: "pointer" }}>
          <Typography variant="body2" color="secondary">
            {truncatedText}
          </Typography>
        </Tooltip>
      );
    } else {
      return text;
    }
  };

  const truncatedUserName = renderTooltip(userName?.charAt(0).toUpperCase() + userName?.slice(1));
  const truncatedEmail = renderTooltip(email);

  return ( 
    <Stack direction="row" spacing={2} alignItems="center">
      <Avatar alt="profile user" src={""} variant='square'/>
      <Stack spacing={0.5}> 
        <Typography variant="subtitle2" sx={{color:'#111A2C'}}>{truncatedUserName}</Typography>
        <Typography variant="subtitle1" sx={{color:'#5C667A'}}>
          {truncatedEmail}
        </Typography>
      </Stack>
      <img src={LogoutIcon} onClick={logOut} width={20} style={{ cursor: "pointer" }} />
    </Stack>
  );
};

export default LogoutContent;
