// project-imports
import menuGroups1 from './menuGroups1'; 
import menuGroups2 from "./menuGroups2"
import menuGroups3 from './menuGroups3';  
// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [menuGroups1,menuGroups2,menuGroups3]
};

export default menuItems;
