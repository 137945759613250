/*
 *
 * request  constants
 *
 */
const key = 'app/request/'
export const requestConstants = {
    REQUEST_DATA : `${key}/REQUEST_DATA`,
    REQUEST_DATA_SUCCESS : `${key}/REQUEST_DATA_SUCCESS`,
    REQUEST_DATA_ERROR : `${key}/REQUEST_DATA_ERROR`,

    GET_REQUEST_DATA : `${key}/GET_REQUEST_DATA`,
    GET_REQUEST_DATA_SUCCESS : `${key}/GET_REQUEST_DATA_SUCCESS`,
    GET_REQUEST_DATA_ERROR : `${key}/GET_REQUEST_DATA_ERROR`,

    ACCEPT_REQUEST_DATA : `${key}/ACCEPT_REQUEST_DATA`,
    ACCEPT_REQUEST_DATA_SUCCESS : `${key}/ACCEPT_REQUEST_DATA_SUCCESS`,
    ACCEPT_REQUEST_DATA_ERROR : `${key}/ACCEPT_REQUEST_DATA_ERROR`,
    CLEAR : `${key}/CLEAR`,
    
    REQUESTFLOW_DATA : `${key}/REQUESTFLOW_DATA`,
};

