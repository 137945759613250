import { sessionService } from '../services';
import { sessionConstants } from '../_constants';
import { encryption } from 'helpers/tools';
import {PASSWORD_ENCRPTION} from "config"
import { snackbarActions, snackbarClose } from 'helpers/tools'; 

function login(payload) {
  if(PASSWORD_ENCRPTION){ 
    payload.password = encryption(payload.password)
  }
  return (dispatch) => {
    dispatch(request({ email:payload.email }));
    sessionService.login(payload).then(
      (response) => {
        if (response.error) {
          dispatch(failure(true, response.message))
          dispatch(snackbarActions(true, response.message));
        } else {
          dispatch(success(response));
          dispatch(snackbarActions(false, response.message))
          setSession(response,payload.email);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      }
    );
  };

  function request(email) {
    return { type: sessionConstants.LOG_IN, email };
  }
  function success(user) {
    return { type: sessionConstants.LOG_IN_SUCCESS, user };
  }

  function failure(error, message) {
    return { type: sessionConstants.LOG_IN_ERROR, error, message };
  }
}

function logout(navigate) {
  return (dispatch) => {
    sessionService.logout(navigate);
    setSession(null);
    dispatch(sessionLogout());
  };
  function sessionLogout() {
    return { type: sessionConstants.LOG_OUT };
  }
}

function register(payload, navigate) {
  return (dispatch) => {
    dispatch(request()); 
    sessionService.register(payload).then(
      (res) => { 
        const { message, error } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(res,message));
          dispatch(snackbarActions(false, message));
          if (navigate) {
            navigate('/wert-success', {state: { logindetails: payload }});
          }
        }
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      },
      (error) => {
        console.error('Registration error:', error);
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };

  function request() {
    return { type: sessionConstants.REGISTER };
  }
  function success(user,message) {
    return { type: sessionConstants.REGISTER_SUCCESS, user,message };
  }
  function failure(error, message) {
    return { type: sessionConstants.REGISTER_ERROR, error, message };
  }
}
function loginSuccess(serviceToken) {
  return (dispatch) => {
    dispatch(success(serviceToken));
  };
  function success(serviceToken) {
    return { type: sessionConstants.LOG_IN_SUCCESS_EXIST, serviceToken };
  }
}

const setSession = (res = null,email) => {
  if (res) {
    const {response} = res 
    const serviceToken = response.id_token;
    window.localStorage.setItem('token', serviceToken);
    window.localStorage.setItem('email', email);
    window.localStorage.setItem('fullname',`${response?.fullname}`)
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
  }
};

function forgotPassword(email, navigate) {
  return (dispatch) => {
    dispatch(request());
    // dispatch(loaderActions.start());
    sessionService.forgotPassword(email).then(
      (res) => {
        const {error, message} = res
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(email));
          dispatch(snackbarActions(false, message));
          if(navigate){
            navigate('/login')
          }
        }
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
          dispatch(failure(true, error.message))
          dispatch(snackbarActions(true, error.message))
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
      }
    );
  };

  function request() {
    return { type: sessionConstants.FORGOT_PASSWORD };
  }
  function success(email) {
    return { type: sessionConstants.FORGOT_PASSWORD_SUCCESS, email };
  }
  function failure(error, message) {
    return { type: sessionConstants.FORGOT_PASSWORD_ERROR, error, message };
  }
}

 

function resetLogin() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: sessionConstants.RESET_LOGIN };
  }
}


export const userActions = {
  login,
  logout,
  register,
  loginSuccess,
  forgotPassword,
  resetLogin
};
