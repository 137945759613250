import { API } from 'aws-amplify';
import { getDefaultParams } from 'helpers/tools';

function transferData(payload) {
  return API.post('gf-service', 'sender', getDefaultParams( {body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getSchema(payload){
  return API.post('gf-service', 'getschema', getDefaultParams( {body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteTransfer(payload){
  const queryParams = payload || { transfer_id: payload?.transfer_id };
  return API.del('gf-service', '/deleteresources/transfer', getDefaultParams(null, { queryStringParameters: queryParams }) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const transferService = {
    transferData,
    getSchema,
    deleteTransfer
};