/*
 *
 * Count constants
 *
 */
const key = 'app/datacount/'
export const dataCountConstants = {
    GET_DATACOUNT : `${key}/GET_DATACOUNT`,
    GET_DATACOUNT_SUCCESS : `${key}/GET_DATACOUNT_SUCCESS`,
    GET_DATACOUNT_ERROR : `${key}/GET_DATACOUNT_ERROR`,
};





