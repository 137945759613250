// material-ui
import { Theme } from '@mui/material/styles';
// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            WebkitTextFillColor: theme.palette.mode == 'dark'? "rgba(248, 249, 250, 0.87)":"#A9A9A9"
          }
        }
      }
    }
  };
}
