import { createContext, useEffect } from 'react';

// third-party
import { CognitoUser } from 'amazon-cognito-identity-js';

// project-imports
import Loader from 'components/Loader';
import { userActions } from 'store/actions';
import { useDispatch, useSelector } from 'store'; 
// ==============================|| AWS COGNITO - CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
  const state = useSelector((state) => state.sessionReducer);
  const { isInitialized } = state;
  const dispatch = useDispatch();
  useEffect(() => {
    const isRefresh = window.performance.navigation.type === 1;

    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('token');
        if (serviceToken) {
          dispatch(userActions.loginSuccess(serviceToken));
        } else if (!isRefresh) {
          dispatch(userActions.logout());
        } 
      } catch (err) {
        console.error(err);
        if (!isRefresh) {
          dispatch(userActions.logout());
        }
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    dispatch(userActions.login(email, password, '/'));
  };

  // const register = (email, password, firstName, lastName) =>
  //   new Promise((success, rej) => {
  //     userPool.signUp(
  //       email,
  //       password,
  //       [
  //         new CognitoUserAttribute({ Name: 'email', Value: email }),
  //         new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
  //       ],
  //       [],
  //       async (err, result) => {
  //         if (err) {
  //           rej(err);
  //           return;
  //         }
  //         success(result);
  //       }
  //     );
  //   });

  const logout = () => {
    dispatch(userActions.logout());
  };

  const forgotPassword = async (email) => {
    const user = new CognitoUser({
      Username: email
    });
    user.forgotPassword({
      onSuccess: function () {},
      onFailure: function () {}
    });
  };

  const updateProfile = () => {};
  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />;
  }

  return <AWSCognitoContext.Provider value={{ ...state, login, logout, forgotPassword, updateProfile }}>{children}</AWSCognitoContext.Provider>;
};

export default AWSCognitoContext;
