import { scenarioService } from '../services';
import { scenarioConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';

function getScenarios(params) {
  return (dispatch) => {
    dispatch(request());
    scenarioService.getScenarios(params)
    .then((res)=> {
      const { response } = res;
      if (response.error) {
        dispatch(failure(true, response.message))
      } else {
        dispatch(success(response)); 
      }
    })
  }
  function request() {
    return { type: scenarioConstants.GET_SCENARIOS };
  }
  function success(records) {
    return { type: scenarioConstants.GET_SCENARIOS_SUCCESS, records };
  }
  function failure(error,message) {
    return { type: scenarioConstants.GET_SCENARIOS_ERROR,error,message };
  }
}


function getScenario(scenarioId) {
  return (dispatch) => {
    dispatch(request());
    scenarioService.getScenario(scenarioId)
    .then((res)=> {
      const { response } = res;
      if (response.error) {
        dispatch(failure(true, response.message))
      } else {
        dispatch(success(response)); 
      }
    })
  }
  function request() {
    return { type: scenarioConstants.GET_SCENARIO };
  }
  function success(record) {
    return { type: scenarioConstants.GET_SCENARIO_SUCCESS, record };
  }
  function failure(error,message) {
    return { type: scenarioConstants.GET_SCENARIO_ERROR,error,message };
  }
}

function createSourceDestination(payload,navigate,scenario) {
  return (dispatch) => {
    dispatch(request());
    scenarioService.createSourceDestination(payload)
    .then((res)=> {
      const { response, message, error } = res;
      if (error) {
        dispatch(failure(true, message))
        dispatch(snackbarActions(error, message))
      } else {
        dispatch(success(response));
        dispatch(snackbarActions(error, message))
        navigate(`/${scenario.key}/list`)
      }
    })
  }
  function request() {
    return { type: scenarioConstants.SOURCE_DESTINATION_DATA };
  }
  function success(record) {
    return { type: scenarioConstants.SOURCE_DESTINATION_DATA_SUCCESS, record };
  }
  function failure(error,message) {
    return { type: scenarioConstants.SOURCE_DESTINATION_DATA_ERROR,error,message };
  }
}

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
      dispatch(request());
      scenarioService.loadRecords(params).then(
        (res) => {
          const { response, error, message, count } = res;
          if (error) {
            dispatch(failure(true, message));
          } else {
            dispatch(success(response, count, page, message));
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
        }
      );  
  };
  function request() {
    return { type: scenarioConstants.LOAD_RECORDS };
  }
  function success(records, total, page, message) {
    return { type: scenarioConstants.LOAD_RECORDS_SUCCESS, records, total, page, message };
  }
  function failure(error, message) {
    return { type: scenarioConstants.LOAD_RECORDS_ERROR, error, message };
  }
} 

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    scenarioService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: scenarioConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: scenarioConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: scenarioConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteScenario(payload) {
  return (dispatch) => {
    dispatch(request());
    scenarioService.deleteScenario(payload)
    .then((res)=> {
      const { response, error, message } = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message)); 
      } else {
        dispatch(success(response));
        dispatch(snackbarActions(false, message)); 
      }
    })
  }
  function request() {
    return { type: scenarioConstants.DELETE_SCENARIO };
  }
  function success(records,message) {
    return { type: scenarioConstants.DELETE_SCENARIO_SUCCESS, records, message};
  }
  function failure(error,message) {
    return { type: scenarioConstants.DELETE_SCENARIO_ERROR, error };
  }
} 

export const scenarioActions = {
  getScenarios,
  getScenario,
  createSourceDestination,
  loadRecords,
  loadRecord,
  deleteScenario
};
