/*
 *
 * upload constants
 *
 */
const key = 'app/upload/'
export const uploadConstants = {
    GET_UPLOAD : `${key}/GET_UPLOAD`,
    GET_UPLOAD_SUCCESS : `${key}/GET_UPLOAD_SUCCESS`,
    GET_UPLOAD_ERROR : `${key}/GET_UPLOAD_ERROR`,
    
    DELETE_UPLOAD : `${key}/DELETE_UPLOAD`,

    CLEAR : `${key}/CLEAR`
};

