import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import * as store from 'store';
import {
  dialogActions
} from 'store/actions';


const AccessError = Loadable(lazy(() => import('components/AccessError/index')));
const RequestList = Loadable(lazy(() => import('pages/apps/request/list')));
const RequestData = Loadable(lazy(() => import('pages/apps/request')));
const SendData = Loadable(lazy(() => import('pages/apps/sendData')));
const Destination = Loadable(lazy(() => import('pages/apps/destination')));
const AddDestination = Loadable(lazy(() => import('pages/apps/destination/AddDestination')));
const Source = Loadable(lazy(() => import('pages/apps/sources')));
const AddSource = Loadable(lazy(() => import('pages/apps/sources/AddSource')));
const Client = Loadable(lazy(() => import('pages/apps/clients')));
const AddClient = Loadable(lazy(() => import('pages/apps/clients/AddClient')));
const Inbound = Loadable(lazy(() => import('pages/apps/transfers/Inbound')));
const Outbound = Loadable(lazy(() => import('pages/apps/transfers/Outbound')));
const LandingPage = Loadable(lazy(() => import('pages/apps/landingPage')));


const generateDynamicPath = (id) => `request-respond/${id}`;


const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),

      children: [
        {
          path: '403',
          element: <AccessError />
        },
        {
          path: generateDynamicPath(':id'),
          element: <SendData dialogActions={dialogActions} {...store} />
        },

        {
          path: 'list',
          element: <RequestList dialogActions={dialogActions} {...store} />
        },
        {
          path: 'requestdata',
          element: (
            <RequestData
              dialogActions={dialogActions}
              {...store}
            />
          )
        },
        {
          path: 'inbound',
          element: <Inbound dialogActions={dialogActions} {...store} />
        },
        {
          path: 'outbound',
          element: (
            <Outbound
              dialogActions={dialogActions}
              {...store}
            />
          )
        },
        {
          path: 'destinations',
          children: [
            {
              path: 'list',
              element: <Destination dialogActions={dialogActions} {...store} />
            },
            {
              path: 'adddestination',
              element: <AddDestination dialogActions={dialogActions} {...store} />
            }
          ]
        },
        {
          path: 'sources',
          children: [
            {
              path: 'list',
              element: <Source dialogActions={dialogActions} {...store} />
            },
            {
              path: 'addsource',
              element: <AddSource dialogActions={dialogActions} {...store} />
            }
          ]
        },
        {
          path: 'clients',
          children : [
            {
              path: 'list',
              element: <Client dialogActions={dialogActions} {...store} />
            },
            {
              path: 'addclient',
              element: <AddClient dialogActions={dialogActions} {...store} />
            }
          ]
        },
        {
        path: 'landingPage',
        element: <LandingPage />
        }
      ]
    }
  ]
};

export default MainRoutes;
