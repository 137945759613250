import { loaderConstants } from '../_constants';
const initialState = {
    inprogress:false,
    ID:null
};
export default function alert(state = initialState, action) { 
    switch (action.type) {
        case loaderConstants.LOADING:            
            return { 
                inprogress: true,
                ID:action.ID
            };
        case loaderConstants.END:
            return { 
                inprogress: false,
                ID:null
            };
        default:
            return {...state}
    }
}