import { createContext, useState } from 'react';   
const PermissionContext = createContext(null);


export const PermissionContextProvider = ({ children }) => { 
  const initialPermission = {}
  const [permissions,setPermissions] = useState(initialPermission)  
  const handlerPermissions = (permissions) => {
       setPermissions(permissions)
  };
  const getModulePermissions = (contextKey) => {
       return permissions[contextKey]
  };
  return (
    <PermissionContext.Provider value={{permissions,getModulePermissions,handlerPermissions}}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContext;
