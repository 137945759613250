import { API } from 'aws-amplify';
import { getDefaultParams } from 'helpers/tools';

function requestData(payload) {
  return API.post('gf-service', 'request', getDefaultParams({body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getRequestData(params) {
  const queryParams = params;
  return API.get('gf-service', 'getrequest', getDefaultParams(null, { queryStringParameters: queryParams }) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function acceptRequestData(datas) {
  let { id, ...filterRecord } = datas;
  return API.put('gf-service', `sender/${id}`, getDefaultParams({ body: filterRecord }) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const requestService = {
    requestData,
    getRequestData,
    acceptRequestData
};