import { useEffect, useState } from 'react';

import Routes from 'routes';
import ThemeCustomization from 'themes';
import {Amplify} from 'aws-amplify';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
// import Customization from 'components/Customization';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import config from 'aws-config';
import { dispatch } from 'store';
import { fetchDashboard } from 'store/reducers/menu'; 
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'; 
import { TableContextProvider  } from 'contexts/TableContext'; 
import { PermissionContextProvider  } from 'contexts/PermissionContext'; 
Amplify.configure({
  ...config
});
const App = () => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchDashboard()).then(() => {
      setLoading(true);
    });
  }, []);

  if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                <PermissionContextProvider>
                  <TableContextProvider>
                   <Routes />
                  </TableContextProvider>
                 </PermissionContextProvider>
                  {/* <Customization /> */}
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
