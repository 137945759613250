import { API } from 'aws-amplify';
import { buildQuery, getDefaultParams } from 'helpers/tools';

function clientData(payload) {
  return API.post('gf-service', 'addclient', getDefaultParams({body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadRecords(params) {
  params = {...params} 
  const queryParams = buildQuery(params)
  return API.get('gf-service', 'getclients', getDefaultParams(null, { queryStringParameters: queryParams }))
      .then((response) => response)
      .catch((error) => Promise.reject(error));
}

function deleteClient(payload){
  return API.del('gf-service', '/deleteresources/client', getDefaultParams({body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const clientService = {
  clientData,
  loadRecords,
  deleteClient
};