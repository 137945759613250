/*
 *
 * Transfer reducer
 *
 */
import produce from 'immer';
import { transferConstants } from '../_constants';

const initialState = {
  loading: false,
  deleteloading: false,
  schemaloading: false,
  success: false,
  error: false,
  disable: false,
  message: '',
  records: [],
  record: [],
  acceptRecord: {},
  schemaRecord: {}
};

/**
 * @param {object} state
 * @param {object} action
 */
const transferReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, records, record } = action;
    switch (action.type) {
      case transferConstants.TRANSFER_DATA:
        draft.loading = true;
        draft.error = false;
        draft.message = message;
        break;
      case transferConstants.TRANSFER_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = message;
        draft.records = records;
        break;
      case transferConstants.TRANSFER_DATA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.records = [];
        break;
      case transferConstants.OUTBOUND_DATA:
        draft.loading = true;
        draft.error = false;
        draft.message = message;
        break;
      case transferConstants.OUTBOUND_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = message;
        draft.record = record;
        break;
      case transferConstants.OUTBOUND_DATA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.record = [];
        break;
      case transferConstants.INBOUND_DATA:
        draft.loading = true;
        draft.error = false;
        draft.message = message;
        break;
      case transferConstants.INBOUND_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = message;
        draft.record = record;
        break;
      case transferConstants.INBOUND_DATA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.record = [];
        break;
      case transferConstants.GET_SCHEMA:
        draft.schemaloading = true;
        break;
      case transferConstants.GET_SCHEMA_SUCCESS:
        draft.schemaRecord = records;
        draft.schemaloading = false;
        break;
      case transferConstants.GET_SCHEMA_ERROR:
        draft.schemaRecord = {};
        draft.schemaloading = false;
        break;
        case transferConstants.DELETE_TRANSFER:
          draft.deleteloading = true;
          draft.success = false;
          draft.error = false;
          draft.message = message;
          break;
        case transferConstants.DELETE_TRANSFER_SUCCESS:
          draft.deleteloading = false;
          draft.success = true;
          draft.error = false;
          draft.message = message;
          break;
        case transferConstants.DELETE_TRANSFER_ERROR:
          draft.deleteloading = false;
          draft.success = true;
          draft.error = error;
          draft.message = message;
          break;case transferConstants.TRANSFER_DATA:
          draft.loading = true;
          draft.error = false;
          draft.message = message;
          break;
        case transferConstants.TRANSFER_DATA_SUCCESS:
          draft.loading = false;
          draft.error = false;
          draft.success = true;
          draft.message = message;
          draft.records = records;
          break;
        case transferConstants.TRANSFER_DATA_ERROR:
          draft.loading = false;
          draft.error = error;
          draft.message = message;
          draft.records = [];
          break;
      case transferConstants.CLEAR:
        draft.success = false;
        break;
    }
  });
export default transferReducer;
