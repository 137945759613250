import { dialogContants } from '../_constants';
const initialState = {
    progress:false,
    error:false
};
export default function alert(state = initialState, action) { 
    switch (action.type) {
        case dialogContants.REQUEST:
            
            return { 
                progress: action.progress,
                error: false
            };
        case dialogContants.SUCCESS:
            return { 
                progress: action.progress,
                error: false
            };
        case dialogContants.ERROR: 
            return { 
                progress: false,
                error: true
            };
        case dialogContants.CLEAR:
            return {...initialState}
        default:
            return {...state}
    }
}