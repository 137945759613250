import { inboundService } from '../services';
import { inBoundConstants } from '../_constants';

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
      dispatch(request());
      inboundService.loadRecords(params).then(
        (res) => {
          const { response, error, message, count } = res;
          if (error) {
            dispatch(failure(true, message));
          } else {
            dispatch(success(response, count, page));
          }
        },
        (error) => {
          if (error && error.message) {
            error = error.message;
          }
          dispatch(failure(true, error.toString()));
        }
      );  
  };
  function request() {
    return { type: inBoundConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: inBoundConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: inBoundConstants.LOAD_RECORDS_ERROR, error, message };
  }
} 

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    inboundService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: inBoundConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: inBoundConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: inBoundConstants.LOAD_RECORD_ERROR, error, message };
  }
}
 

export const inboundActions = {
  loadRecords,
  loadRecord
};
