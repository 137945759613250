// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, useMediaQuery } from '@mui/material';

// project-imports
import DrawerHeaderStyled from './DrawerHeaderStyled';

import { DRAWER_WIDTH, HEADER_HEIGHT } from 'config';
import useConfig from 'hooks/useConfig';
import Logout from '../Logout/index';
// types
import { LAYOUT_CONST } from 'types/config';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  return (
    <>
      <DrawerHeaderStyled
        theme={theme}
        open={open}
        sx={{
          minHeight: isHorizontal ? 'unset' : HEADER_HEIGHT + 1,
          width: isHorizontal ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
          paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '0px',
          paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '0px',
          paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '10px' : 0
        }}
      >
        <Logout />
      </DrawerHeaderStyled>
      <Divider sx={{
        borderWidth: '-1px',
        borderColor: 'primary.300',
        borderBottomWidth: 'inherit'
      }}></Divider>
    </>
  );
};

export default DrawerHeader;
