// project-imports
import services from 'utils/mockAdapter';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

const dashboard: NavItemType = {
  id: 'group-dashboard',
  title: '',
  type: 'group',
  icon: 'navigation',
  children: [
    {
      id: 'dashboard',
      title: 'dashboard',
      type: 'collapse',
      icon: 'dashboard',
      children: [
        {
          id: 'general',
          title: 'general',
          type: 'item',
          url: '/dashboard/general'
        },
        {
          id: 'statistics',
          title: 'statistics',
          type: 'item',
          url: '/dashboard/statistics'
        }
      ]
    }
  ]
};

// ==============================|| DASHBOARD - GET ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard: dashboard });
