import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery,CircularProgress  } from '@mui/material';

// project-imports
import Dot from 'components/@extended/Dot';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { activeItem } from 'store/reducers/menu';
import useTableContenxt from 'hooks/useTableContext'
// types
import { LinkTarget, NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';
import SvgIcons  from "components/SvgElement";
import selectedRequestIcon from 'assets/icons/request_selected.svg';
import selectedSendIcon from 'assets/icons/send_selected.svg';
// ==============================|| NAVIGATION - ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const {onResetContext}:any = useTableContenxt()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const { counts, loading }:any = useSelector((state) => state.count);
  const { menuOrientation } = useConfig();

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon!;
  const itemIcon = item.icon ? (item.iconType == 'img'? <SvgIcons icon={item.icon!}/>:<Icon variant="Bulk" size={drawerOpen ? 20 : 22} />) : false;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname && pathname.includes('kanban')) {
      if (item.url && item.url.includes('kanban')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    } 
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === 'dark' ? 'secondary.400' : 'secondary.main';
  const iconSelectedColor = 'primary.main';  
  
  return (
    <>
      {menuOrientation === LAYOUT_CONST.VERTICAL_LAYOUT || downLG ? (
        <ListItemButton
          {...listItemProps}
          disabled={item.disabled}
          selected={isSelected}
          onClick={onResetContext}
          sx={{
            zIndex: 1201,
            pl: drawerOpen ? `${level * 20}px` : 1.5,
            py: !drawerOpen && level === 1 ? 1.25 : 1,
            // ...(drawerOpen && {
            //   color: item.id === 'senddata' ? 'rgba(42, 162, 97, 1)' :  item.id === 'requestdata' ? 'rgba(71, 126, 179, 0.06)' : ''  ,
            //   '&:hover': {
            //     bgcolor: 'transparent'
            //   },
            //   '&.Mui-selected': {
            //     '&:hover': {
            //       bgcolor: 'transparent'
            //     },
            //     bgcolor: 'transparent'
            //   }
            // }),
            ...(drawerOpen &&
              level === 1 && {
                mx: 2,
                mt: item.id === 'senddata' ? '4px' : item.id === 'inbound' ? '18px': item.id === 'outbound' || item.id === 'folders' ? '-4px' : item.id === 'clients' ? '14px'  : '8px',
                mb: item.id === 'requestdata' ? '20px' : item.id === 'folders' ? '18px' : item.id === 'clients' || item.id === 'sources' || item.id === 'destinations' ? '-10px' : '8px',
                borderRadius: 0.5,
                border: item.id === 'senddata' ? '1px solid rgba(42, 162, 97, 0.24)' : item.id === 'requestdata' ? '1px solid rgba(71, 126, 179, 0.24)':'',
                bgcolor: item.id === 'senddata'? 'rgba(42, 162, 97, 0.06)' :  item.id === 'requestdata' ? 'rgba(71, 126, 179, 0.06)' : '' ,
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'secondary.200'
                },
                '&.Mui-selected': {
                  bgcolor: item.id === 'senddata'? '#2AA261' : item.id === 'requestdata' ? '#3A73AA' : '#F9EDCE'  ,
                  color: iconSelectedColor,
                  '&:hover': {
                    bgcolor: item.id === 'senddata' ? '#2AA261' : item.id === 'requestdata' ? '#3A73AA' : '#F9EDCE'
                  }
                }
              }),
            // ...(!drawerOpen && {
            //   px: 2.75,
            //   '&:hover': {
            //     bgcolor: 'transparent'
            //   },
            //   '&.Mui-selected': {
            //     '&:hover': {
            //       bgcolor: 'transparent'
            //     },
            //     bgcolor: 'transparent'
            //   }
            // })
          }}
          // {...(downLG && {
          //   onClick: () => {dispatch(openDrawer(false));onResetContext()}
          // })}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 38,
                '& .MuiIcon-root': {
                  height: '30px'
                },
                color: isSelected ? iconSelectedColor : textColor,
                ...(!drawerOpen &&
                  level === 1 && {
                    borderRadius: 1,
                    width: 46,
                    height: 46,
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.200'
                    }
                  }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: theme.palette.mode === 'dark' ? 'secondary.100' : 'primary.lighter',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'secondary.200' : 'primary.lighter'
                    }
                  })
              }}
            >
             { isSelected && item.id === 'senddata' ? <SvgIcons icon={selectedSendIcon} /> : isSelected && item.id === 'requestdata' ? <SvgIcons icon={selectedRequestIcon}/> :  itemIcon}
            </ListItemIcon>
          )}

          {!itemIcon && drawerOpen && (
            <ListItemIcon
              sx={{
                minWidth: 30
              }}
            >
              <Dot size={isSelected ? 6 : 5} color={isSelected ? 'primary' : 'secondary'} />
            </ListItemIcon>
          )}

          {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <ListItemText
              sx={{mb:0.5,ml:-1}}
              primary={
                <Typography variant="h4" sx={{ color: item.id === 'senddata' ? isSelected ?'#FFFFFF' :'#2AA261' :  item.id === 'requestdata' ? isSelected ?'#FFFFFF': '#3A73AA' : '#111A2C'  }}>
                  {item.title}
                </Typography>
              }
            />
          )}
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <>
              {!loading?<Chip
                sx={{...item.chip.sx}}
                color={item.chip.color}
                variant={item.chip.variant}
                size={item.chip.size}
                label={item.key && counts[item.key]?counts[item.key]:item.chip.label}
                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              />:<CircularProgress size={16}/>}
            </>
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          {...listItemProps}
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            ...(drawerOpen && {
              '&:hover': {
                bgcolor: 'transparent'
              },
              '&.Mui-selected': {
                bgcolor: 'transparent',
                color: iconSelectedColor,
                '&:hover': {
                  color: iconSelectedColor,
                  bgcolor: 'transparent'
                }
              }
            }),
            ...(!drawerOpen && {
              '&:hover': {
                bgcolor: 'transparent'
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: 'transparent'
                },
                bgcolor: 'transparent'
              }
            })
          }}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                '& .MuiIcon-root': {
                  height: '30px'
                },
                minWidth: 36,
                ...(!drawerOpen && {
                  borderRadius: 1,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    bgcolor: 'transparent'
                  }
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  })
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          <ListItemText
            sx={{mb:0}}
            primary={
              <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor, fontWeight: isSelected ? 500 : 400 }}>
                {item.title}
              </Typography>
            }
          />
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <>
              {!loading?<Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.key && counts[item.key]?counts[item.key]:0}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              sx={{ ml: 1, ...item.chip.sx}}
            />:<CircularProgress/>}
            </>
          )}
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;
