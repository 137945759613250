//import { Auth } from "aws-amplify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const dev = {
  API: {
    endpoints: [{
      name: "no-auth",
      endpoint: API_BASE_URL ? API_BASE_URL : "https://api.demo.generalfolders.com/demo/"
    }, {
      name: "gf-service",
      endpoint: API_BASE_URL ? API_BASE_URL : "https://api.demo.generalfolders.com/demo/",
      // custom_header: async () => {
      //   return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
      // }
    }
    ]
  },
  CDN_BASE_URL: API_BASE_URL.includes('lab') ? "https://cdn.lab.generalfolders.com" : "https://cdn.demo.generalfolders.com"
};

export const prod = {
  API: {
    endpoints: [{
      name: "no-auth",
      endpoint: API_BASE_URL ? API_BASE_URL : "https://api.demo.generalfolders.com/"
    }, {
      name: "gf-service",
      endpoint: API_BASE_URL ? API_BASE_URL : "https://api.demo.generalfolders.com/",
      // custom_header: async () => {
      //   return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
      // }
    }
    ]
  }
};

let config = {};
if(process.env.REACT_APP_STAGE === "prod"){
  config = {  
    ...prod
  };
}else if(process.env.REACT_APP_STAGE === "dev"){
  config = {  
    ...dev
  };
}else{
  config = {  
    ...dev
  };
}

export default config