import { getDefaultParams, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

function loadRecords(params) {
    params = {...params,...{ queryParams : {job_type: 'inbound' }}} 
    const queryParams = buildQuery(params)
    return API.get('gf-service', 'gettransfers', getDefaultParams(null, { queryStringParameters: queryParams }))
        .then((response) => response)
        .catch((error) => Promise.reject(error));
}

export const inboundService = {
    loadRecords
};