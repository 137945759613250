// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, Messages2, Calendar1, Kanban, Profile2User, Bill, UserSquare, ShoppingBag } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

import {COUNT_BACKGROUND_COLOR, COLOR} from "config"
import inBoundIcon from "assets/icons/inbound_icon.svg"
import outBoundIcon from "assets/icons/outbound_icon.svg"
import foldersIcon from "assets/icons/folders_icon.svg"
// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
  icon: icons.applications,
  type: 'group',
  divider:true,
  children: [
    {
      id: 'inbound',
      parent: '',
      icon: inBoundIcon,
      iconType: 'img',
      title: <FormattedMessage id="inbound" />,
      type: 'item',
      url: '/inbound',
      key:'inbound',
      chip:{
        label:'0',
        color:'secondary',
        size:'small',
        sx:{ 
          '&.MuiChip-root': {
            borderRadius: '4px',
            background: COUNT_BACKGROUND_COLOR,
            color:COLOR
          }
        }
      }
    },
    {
      id: 'outbound',
      parent: '',
      icon: outBoundIcon,
      iconType: 'img',
      title: <FormattedMessage id="outbound" />,
      type: 'item',
       url: '/outbound',
       key:'outbound',
       chip:{
          label:'0',
          color:'secondary',
          size:'small',
          sx:{ 
            '&.MuiChip-root': {
              borderRadius: '4px',
              background: COUNT_BACKGROUND_COLOR,
              color:COLOR
            }
          }
        }
    },
    {
      id: 'folders',
      parent: '',
      icon: foldersIcon,
      iconType: 'img',
      title: <FormattedMessage id="Folders" />,
      type: 'item',
       url: '/folders',
       key:'folders',
       chip:{
          label:'0',
          color:'secondary',
          size:'small',
          sx:{ 
            '&.MuiChip-root': {
              borderRadius: '4px',
              background: COUNT_BACKGROUND_COLOR,
              color:COLOR
            }
          }
        }
    }
  ]
};

export default applications;
