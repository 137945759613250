import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom'; 
// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container } from '@mui/material'; 

// project-imports
import Drawer from './Drawer';
import Header from './Header';  
import HorizontalBar from './Drawer/HorizontalBar';
//import Breadcrumbs from 'components/@extended/Breadcrumbs';

//import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu'; 
// types
import { LAYOUT_CONST } from 'types/config';
import { DRAWER_WIDTH } from 'config'; 

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme(); 
  // const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(!drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
      setOpen(!downLG);
      dispatch(openDrawer({ drawerOpen: !downLG }));
  }, [downLG]);
   

  // useEffect(() => {
  //   if (open !== drawerOpen) setOpen(drawerOpen); 
  // }, [drawerOpen]);
   
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {!isHorizontal ? <Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> : <HorizontalBar />}

      <Box component="main" sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, pt: { xs: 9, sm: 9 } }}> 
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { p: { xs: 0,sm:"0 !important",md:0,lg:0 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            background:'#ffffff',
            flexDirection: 'column',
            '&.MuiContainer-root':{padding: '0px 0px !important'}
          }}
        >
          {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
          <Outlet />  
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
