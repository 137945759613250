import { useRoutes } from 'react-router-dom'; 

// project-imports 
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';  
import MaintanenceRoutes from './MaintanenceRoutes';  
import EmptyRoutes from './EmptyRoutes';  

export default function ThemeRoutes() { 
  return useRoutes([LoginRoutes, MainRoutes,MaintanenceRoutes,EmptyRoutes]);
}
