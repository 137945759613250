// material-ui
import { Theme, TypographyVariantsOptions } from '@mui/material/styles';

// types
import { FontFamily, ThemeMode } from 'types/config';

// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (mode: ThemeMode, fontFamily: FontFamily, theme: Theme): TypographyVariantsOptions => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 400,
    fontSize: '57.6px',
    lineHeight: '63.36px'
  },
  h2: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px'
  },
  h3: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px'
  },
  h4: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  h5: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '33.2px'
  },
  h6: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '31.6px'
  },
  caption: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px'
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: 1.57
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: 1.66
  },
  subtitle1: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: "20px"
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    textTransform: 'capitalize'
  }
});

export default Typography;
