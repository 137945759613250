/*
 *
 * Upload reducer
 *
 */
import produce from 'immer';
import { uploadConstants } from '../_constants';

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
  records: [],
  record: []
};

/**
 * @param {object} state
 * @param {object} action
 */
const uploadReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, records } = action;
    switch (action.type) {
      case uploadConstants.GET_UPLOAD:
        draft.loading = true;
        draft.error = false;
        draft.message = '';
        break;
      case uploadConstants.GET_UPLOAD_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = '';
        draft.records = records;
        break;
      case uploadConstants.GET_UPLOAD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.records = [];
        break;
      case uploadConstants.DELETE_UPLOAD:
        draft.records = [];
        break;
      case uploadConstants.CLEAR:
        draft.records = [];
        break;
      default:
        break;
    }
  });
export default uploadReducer;
