import { transferService } from '../services';
import { transferConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { countActions } from './counts.actions';

function transferData(payload,handleReset,navigate) {
  return (dispatch) => {
    dispatch(request());
    transferService.transferData(payload)
    .then((res)=> {
      const { response, message, error } = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message));
      } else {
        dispatch(success(response,message)); 
        dispatch(snackbarActions(false, message));
        dispatch(countActions.getMegaMenuCounts())
        handleReset()
        navigate('/outbound')
      }
    })
  }
  function request() {
    return { type: transferConstants.TRANSFER_DATA };
  }
  function success(records,message) {
    return { type: transferConstants.TRANSFER_DATA_SUCCESS, records ,message};
  }
  function failure(error,message) {
    return { type: transferConstants.TRANSFER_DATA_ERROR,error,message };
  }
} 

function getSchema(payload) {
  return (dispatch) => {
    dispatch(request());
    transferService.getSchema(payload)
    .then((res)=> {
      const { response, error } = res;
      if (error) {
        dispatch(failure(true))
      } else {
        dispatch(success(response)); 
      }
    })
  }
  function request() {
    return { type: transferConstants.GET_SCHEMA };
  }
  function success(records,message) {
    return { type: transferConstants.GET_SCHEMA_SUCCESS, records};
  }
  function failure(error,message) {
    return { type: transferConstants.GET_SCHEMA_ERROR, error };
  }
} 

function deleteTransfer(payload) {
  return (dispatch) => {
    dispatch(request());
    transferService.deleteTransfer(payload)
    .then((res)=> {
      const { response, error, message } = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message)); 
      } else {
        dispatch(success(response));
        dispatch(snackbarActions(false, message)); 
      }
    })
  }
  function request() {
    return { type: transferConstants.DELETE_TRANSFER };
  }
  function success(records,message) {
    return { type: transferConstants.DELETE_TRANSFER_SUCCESS, records, message};
  }
  function failure(error,message) {
    return { type: transferConstants.DELETE_TRANSFER_ERROR, error };
  }
} 

function clear() {
  return (dispatch) => {
    dispatch(request());
  }
  function request() {
    return { type: transferConstants.CLEAR };
  }
}
export const transferActions = {
    transferData,
    getSchema,
    clear,
    deleteTransfer
};
