/*
 *
 * Request reducer
 *
 */
import produce from 'immer';
import { requestConstants } from '../_constants';

const initialState = {
  acceptLoading: false,
  success: false,
  error: false,
  disable: false,
  message: '',
  records: [],
  record: {},
  acceptRecord: {},
  requestRecord: {},
  reset: false
};

/**
 * @param {object} state
 * @param {object} action
 */
const requestReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, records, record } = action;
    switch (action.type) {
      case requestConstants.REQUEST_DATA:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case requestConstants.REQUEST_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.records = records;
        break;
      case requestConstants.REQUEST_DATA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.records = [];
        break;
      case requestConstants.GET_REQUEST_DATA:
        draft.loading = true;
        draft.error = false;
        draft.message = message;
        break;
      case requestConstants.GET_REQUEST_DATA_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = message;
        draft.record = record;
        break;
      case requestConstants.GET_REQUEST_DATA_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.records = [];
        break;
      case requestConstants.ACCEPT_REQUEST_DATA:
        draft.acceptLoading = true;
        draft.error = false;
        draft.message = message;
        draft.disable = false;
        break;
      case requestConstants.ACCEPT_REQUEST_DATA_SUCCESS:
        draft.acceptLoading = false;
        draft.error = false;
        draft.success = true
        draft.message = message;
        draft.acceptRecord = record;
        draft.disable = message.includes('Rejected') ? false : true;
        break;
      case requestConstants.ACCEPT_REQUEST_DATA_ERROR:
        draft.acceptLoading = false;
        draft.error = error;
        draft.message = message;
        draft.disable = true;
        break;
      case requestConstants.CLEAR:
        draft.success = false;
        draft.reset = true;
        draft.requestRecord = {};
        break;
      case requestConstants.REQUESTFLOW_DATA:
        draft.requestRecord = records;
        draft.reset = false;
        break;
      default:
        break;
    }
  });
export default requestReducer;
