// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
import chat from './chat';
// import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar'; 
import cartReducer from './cart'; 
import sessionReducer from './session.reducers';
//import invoice from './invoice'; 
import alert from './alert.reducers';
import dialog from './dialog.reducers';
import loader from './loader.reducers';

import scenario from './scenario.reducers';
import upload from './upload.reducers';
import request from './request.reducers';
import transfer from './transfer.reducers';
import count from './counts.reducers';
import inBound from './inbound.reducers';
import outBound from './outbound.reducers';
import client from './client.reducers';

const reducers = combineReducers({
  chat,
  // calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ), 
  sessionReducer, 
  alert, 
  dialog,
  loader, 
  scenario,
  upload,
  request,
  transfer,
  count: persistReducer(
    {
      key: 'counter',
      storage
    },
    count
  ),
  inBound,
  outBound,
  client

});

export default reducers;
