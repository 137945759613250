import { requestService } from '../services';
import { requestConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { countActions } from './counts.actions';

const listUrl = "/outbound"

function requestData(payload, details, navigate) {
  return (dispatch) => {
    dispatch(request());
    requestService.requestData(payload)
    .then((res)=> {
      const { response , message, error} = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message))
      } else {
        dispatch(success(response)); 
        dispatch(snackbarActions(false, message))
        dispatch(countActions.getMegaMenuCounts())
        navigate('/request-success', { state: details })
      }
    })
  }
  function request() {
    return { type: requestConstants.REQUEST_DATA };
  }
  function success(records) {
    return { type: requestConstants.REQUEST_DATA_SUCCESS, records };
  }
  function failure(error,message) {
    return { type: requestConstants.REQUEST_DATA_ERROR,error,message };
  }
}

function getRequestData(params) {
  return (dispatch) => {
    dispatch(request());
    requestService.getRequestData(params)
    .then((res)=> {
      const { response , message, error} = res;
      if (error) {
        dispatch(failure(true,message))
      } else {
        dispatch(success(response,message)); 
      }
    })
  }
  function request() {
    return { type: requestConstants.GET_REQUEST_DATA };
  }
  function success(record,message) {
    return { type: requestConstants.GET_REQUEST_DATA_SUCCESS, record,message };
  }
  function failure(error,message) {
    return { type: requestConstants.GET_REQUEST_DATA_ERROR,error,message };
  }
}

function acceptRequestData(datas,navigate) {
  return (dispatch) => {
    dispatch(request());
    requestService.acceptRequestData(datas)
    .then((res)=> {
      const { response ,message, error} = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message));
        dispatch(dialogActions.error(false));
      } else {
        dispatch(dialogActions.success(false));
        dispatch(success(response,message)); 
        dispatch(snackbarActions(false, message));   
        dispatch(countActions.getMegaMenuCounts())     
        if(navigate){
          navigate(listUrl)
        }
      }
    })
  }
  function request() {
    return { type: requestConstants.ACCEPT_REQUEST_DATA };
  }
  function success(record,message) {
    return { type: requestConstants.ACCEPT_REQUEST_DATA_SUCCESS, record,message};
  }
  function failure(error,message) {
    return { type: requestConstants.ACCEPT_REQUEST_DATA_ERROR,error,message };
  }
}


function clear() {
  return (dispatch) => {
    dispatch(request());
  }
  function request() {
    return { type: requestConstants.CLEAR };
  }
}

function requestFlowData(records) {
  return (dispatch) => {
    dispatch(request(records));
  }
  function request(records) {
    return { type: requestConstants.REQUESTFLOW_DATA, records };
  }
}

export const requestActions = {
    requestData,
    getRequestData,
    acceptRequestData,
    clear,
    requestFlowData
};
