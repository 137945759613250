// import { API } from 'aws-amplify';
import { encrypt } from 'n-krypta';
import {PASSWORD_KEY} from "config"
import dayjs from 'dayjs';
import { openSnackbar, closeSnackbar, openSlientSnackbar } from 'store/reducers/snackbar';
/**
 *
 * @param {function} columns
 * @param {object} record
 * @param {object} locationState
 */
export function resolveColumns(columns, record, locationState, metaData) {
  const schema = typeof columns === 'function' ? columns(record, metaData) : columns;
  const section = (schema && typeof schema === 'object' && schema.section) || false;
  const sectionColumns = (section && locationState && locationState.schemaId && section.find((_) => _.schemaId === locationState.schemaId)) || false;

  return (sectionColumns && sectionColumns.columns) || (schema && schema.columns) || schema;
}

export const dummyData = (fields) =>
  Array.from(Array(5), (x, index) => {
    const columns = fields && fields.length > 0 && fields.reduce((k, el) => Object.assign({}, k, { [el.value]: '' }), {});
    return Object.assign({}, columns, { _id: index + 1, loading: true });
  });

export const getDefaultParams = (payload, queryString, isAuthRequired = true) => {
  let param = Object.assign({}, payload, queryString);
  if (isAuthRequired) {
    param.headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`
    };
  }

  return param;
};
export const getPdfParams = (payload, queryString, isAuthRequired = true) => {
  let param = Object.assign({}, payload, queryString);
  if (isAuthRequired) {
    param.headers = {
      'Accept': '*/*',
      authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type':'application/pdf'
    };
  }

  return param;
};

export const dateFormat = (date) =>{
  const convertedValue = dayjs(date).format('D MMM, YYYY');
  return convertedValue;
}

export const getDefaultParamswithoutlimitkey = (payload, queryString) => {
  return Object.assign({}, payload, queryString);
};
export const getParams = (payload, isAuthRequired) => {
  let params = Object.assign({}, payload);
  if (isAuthRequired) {
    params.headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`
    };
  }
  return;
};

export function encryption(data) { 
  const encryptedData = encrypt(data, PASSWORD_KEY);
  return encryptedData
} 

export function get_module_authorization(premissions, module) {
  if (premissions) {
    return premissions[module] ? premissions[module] : {};
  }
  return {};
}

export function snackbarActions(error, message, userInteraction = false) {
  return (dispatch) => {
    if (error) {
      if (userInteraction) {
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'userInteraction',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true,
            error:true

          })
        );
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true,
            error:true
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          severity: 'success',
          close: true,
          error:false
        })
      );
    }
  };
}

export function snackbarClose() {
  return (dispatch) => {
    dispatch(closeSnackbar());
  };
}

export function slientSnackbar(message) {
  return (dispatch) => {
    dispatch(openSlientSnackbar({ message }));
  };
}

export function isEmpty(object = {}) {
  if (object && Object.keys(object).length) return false;
  return true;
}

export const buildQuery = (params) => {
  const { pageSize, page, delivery_date, queryParams } = params;
  let { filters, columnFilters } = params;
  let conditional_operator = 'or';
  if (columnFilters && !isEmpty(columnFilters)) {
    filters = columnFilters;
    conditional_operator = 'and';
  }
  const queryObject = { pageSize, page, delivery_date };
  if (filters && !isEmpty(filters)) {
    if (typeof filters == 'string') {
      queryObject.filters = filters;
    } else {
      let arr = [];
      let keys = Object.keys(filters);
      keys.forEach((key) => {
        if (filters[key]) {
          arr.push(`${key}=${filters[key]}`);
        }
      });
      if (arr.length) {
        queryObject.filters = arr.join(',');
        queryObject.conditional_operator = conditional_operator;
      }
    }
  }
  if (queryParams && !isEmpty(queryParams)) {
    let keys = Object.keys(queryParams);
    keys.forEach((key) => {
      if (queryParams[key]) {
        queryObject[key] = queryParams[key];
      }
    });
  }
  return JSON.parse(JSON.stringify(queryObject));
};

export const CREATE_SUCCESS = 'createSuccess';

export const isValidCacheSession = ({invalidateCache,lastUpdate})=>{
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const VALID_CACHE_DIFF = -120;
  const isValid = (lastUpdate - currentTimestamp) > VALID_CACHE_DIFF
  if(!invalidateCache && isValid){
    return true
  }
  return false
}

export const timeStampToLocalDate = (timestamp,locale = "en-IN")=>{
  return new Date(timestamp).toLocaleString(locale)
}