/*
 *
 * Client constants
 *
 */
const key = 'app/client/'
export const clientConstants = {
    CLIENT_DATA : `${key}/CLIENT_DATA`,
    CLIENT_DATA_SUCCESS : `${key}/CLIENT_DATA_SUCCESS`,
    CLIENT_DATA_ERROR : `${key}/CLIENT_DATA_ERROR`,

    LOAD_RECORDS: `${key}/LOAD_RECORDS`,
    LOAD_RECORDS_SUCCESS: `${key}/LOAD_RECORDS_SUCCESS`,
    LOAD_RECORDS_ERROR: `${key}/LOAD_RECORDS_ERROR`,

    LOAD_RECORD: `${key}/LOAD_RECORD`,
    LOAD_RECORD_SUCCESS: `${key}/LOAD_RECORD_SUCCESS`,
    LOAD_RECORD_ERROR: `${key}/LOAD_RECORD_ERROR`,

    CLIENT_DELETE: `${key}/CLIENT_DELETE`,
    CLIENT_DELETE_SUCCESS: `${key}/CLIENT_DELETE_SUCCESS`,
    CLIENT_DELETE_ERROR: `${key}/CLIENT_DELETE_ERROR`
};



