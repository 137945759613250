/*
 *
 * inbound  constants
 *
 */
const key = 'app/inbound/'
 
const LOAD_RECORDS = `${key}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${key}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${key}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${key}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${key}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${key}/LOAD_RECORD_ERROR`;

export const inBoundConstants = {
    LOAD_RECORDS,
    LOAD_RECORDS_SUCCESS,
    LOAD_RECORDS_ERROR,
    LOAD_RECORD,
    LOAD_RECORD_SUCCESS,
    LOAD_RECORD_ERROR
};


