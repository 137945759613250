import { clientService } from '../services';
import { clientConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';

function clientData(payload, navigate, fromGetStarted, handleDataStore) {
  return (dispatch) => {
    dispatch(request());
    clientService.clientData(payload)
    .then((res)=> {
      const { response,message, error } = res;
      if (error) {
        dispatch(failure(true, message))
        dispatch(snackbarActions(error,message))
      } else {
        dispatch(success(response)); 
        dispatch(snackbarActions(error,message))
        if(fromGetStarted){
          navigate(-1)
          handleDataStore()
        }
        else{
          navigate('/clients/list')
        }
      }
    })
  }
  function request() {
    return { type: clientConstants.CLIENT_DATA };
  }
  function success(counts) {
    return { type: clientConstants.CLIENT_DATA_SUCCESS, counts };
  }
  function failure(error,message) {
    return { type: clientConstants.CLIENT_DATA_ERROR,error,message };
  }
}


function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    clientService.loadRecords(params).then(
      (res) => {
        const { response, error, message, count } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, count, page, message));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: clientConstants.LOAD_RECORDS };
  }
  function success(records, total, page, message) {
    return { type: clientConstants.LOAD_RECORDS_SUCCESS, records, total, page, message };
  }
  function failure(error, message) {
    return { type: clientConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    clientService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: clientConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: clientConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: clientConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteClient(payload) {
  return (dispatch) => {
    dispatch(request());
    clientService.deleteClient(payload)
    .then((res)=> {
      const { response, error, message } = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message)); 
      } else {
        dispatch(success(response));
        dispatch(snackbarActions(false, message)); 
      }
    })
  }
  function request() {
    return { type: clientConstants.CLIENT_DELETE };
  }
  function success(records,message) {
    return { type: clientConstants.CLIENT_DELETE_SUCCESS, records, message};
  }
  function failure(error,message) {
    return { type: clientConstants.CLIENT_DELETE_ERROR, error };
  }
} 

export const clientActions = {
    clientData,
    loadRecords,
    loadRecord,
    deleteClient
};
