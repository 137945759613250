import { API } from 'aws-amplify';
import { getDefaultParams } from 'helpers/tools';

function getMegaMenuCounts() {
  return API.get('gf-service', 'datascount', getDefaultParams() )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const countService = {
    getMegaMenuCounts
};