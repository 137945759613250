import { useContext } from 'react';

// table provider 
import TableContext from 'contexts/TableContext'; 

// ==============================|| HOOKS - AUTH ||============================== //

const useTableContenxt = () => {
  const context = useContext(TableContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useTableContenxt;
