/*
 *
 * transfer  constants
 *
 */
const key = 'app/transfer/'
export const transferConstants = {
    TRANSFER_DATA : `${key}/TRANSFER_DATA`,
    TRANSFER_DATA_SUCCESS : `${key}/TRANSFER_DATA_SUCCESS`,
    TRANSFER_DATA_ERROR : `${key}/TRANSFER_DATA_ERROR`,

    GET_SCHEMA : `${key}/GET_SCHEMA`,
    GET_SCHEMA_SUCCESS : `${key}/GET_SCHEMA_SUCCESS`,
    GET_SCHEMA_ERROR : `${key}/GET_SCHEMA_ERROR`,

    DELETE_TRANSFER : `${key}/DELETE_TRANSFER`,
    DELETE_TRANSFER_SUCCESS : `${key}/DELETE_TRANSFER_SUCCESS`,
    DELETE_TRANSFER_ERROR : `${key}/DELETE_TRANSFER_ERROR`,

    CLEAR : `${key}/CLEAR`
};

