import { lazy } from 'react';
import Loadable from 'components/Loadable';
const Maintenance404 = Loadable(lazy(() => import('pages/maintenance/404')));

const MaintanenceRoutes = {
  path: '*',
  element: (  
      <Maintenance404 /> 
    )
};

export default MaintanenceRoutes;
