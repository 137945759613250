// import { useMemo } from 'react';

// material-ui 
import { Box, useMediaQuery } from '@mui/material';

// project-imports
import Search from './Search';  
import Notification from './Notification'; 
import MobileSection from './MobileSection';
// import MegaMenuActions from './MegaMenuActions';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// type
import { LAYOUT_CONST } from 'types/config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const {
    // i18n,
    menuOrientation
  } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Search />}
      {/* {!downLG && <MegaMenuActions/>} */}
      {/* {!downLG && localization} */}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      {!downLG && <Notification />} 
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
