import { API } from 'aws-amplify';

function login(payload) {
  return API.post('no-auth', 'user/login', { body: payload })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function logout(navigate) {
  localStorage.clear()
  if(navigate){
    navigate('/login')
  }  
}

function register(payload) {
  return API.post('no-auth', 'user/signup', { body: payload })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}


function forgotPassword(payload) {
  return API.post('no-auth', 'user/passwordchange', { body: payload })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
export const sessionService = {
  login,
  logout,
  register,
  forgotPassword
};
