// third-party
import { FormattedMessage } from 'react-intl'; 
import sourceIcon from "assets/icons/sources_icon.svg"
import destinationIcon from "assets/icons/destination_icon.svg"
import partnersIcon from "assets/icons/partner_icon.svg"
import settingsIcon from "assets/icons/settings_icon.svg"
import { NavItemType } from 'types/menu'; 
// ==============================|| MENU ITEMS - APPLICATIONS ||============================== // 
const applications: NavItemType = {
  type: 'group',
  children: [
    {
      id: 'clients',
      parent: '',
      title: <FormattedMessage id="clients" />,
      icon: partnersIcon,
      iconType: 'img',
      type: 'item',
      url: '/clients/list'
    },
    {
      id: 'sources',
      parent: '',
      title: <FormattedMessage id="sources" />,
      icon: sourceIcon,
      iconType: 'img',
      type: 'item',
      url: '/sources/list'
    },
    {
      id: 'destinations',
      parent: '',
      title: <FormattedMessage id="destinations" />,
      icon: destinationIcon,
      iconType: 'img',
      type: 'item',
      url: '/destinations/list'
    },
    {
      id: 'settings',
      parent: '',
      title: <FormattedMessage id="settings" />,
      icon: settingsIcon,
      iconType: 'img',
      type: 'item',
      url: '/settings'
    }
  ]
};

export default applications;
