// material-ui
import { Box, FormControl, OutlinedInput, InputAdornment } from '@mui/material';

// assets
import SvgIcons from 'components/SvgElement';
import starIcon from 'assets/icons/ai-star.svg';
// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => (
  <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>

    <FormControl sx={{ width: { xs: '100%', md: '98%' } }}>
      <OutlinedInput
        id="header-search"
        startAdornment={
          <InputAdornment position="start" sx={{ mr: -0.5 }}>
            <SvgIcons icon={starIcon} />
          </InputAdornment>
        }
        aria-describedby="header-search-text"
        inputProps={{
          'aria-label': 'weight'
        }}
        placeholder="Looking for something? Ask us a question or look up sources, destinations, and partners."
        sx={{
          '& .MuiOutlinedInput-input': {
            p: 2,
          },
          '.MuiOutlinedInput-notchedOutline' : {
            borderColor: 'transparent',
            borderWidth: 0, 
          },
          '&.Mui-focused' : {
            boxShadow: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              borderWidth: 0, 
            }
          }
        }}
      />
    </FormControl>
  </Box>
);

export default Search;
