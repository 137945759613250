// material-ui
import { Stack, Typography } from '@mui/material';
import { SIDEBAR_BACKGROUND_COLOR } from "config"
// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack direction="row" justifyContent="center" alignItems="center" sx={{ backgroundColor: SIDEBAR_BACKGROUND_COLOR }}>
    <Stack spacing={1.5} direction="row" justifyContent="center" alignItems="center">
      <Typography variant="caption" sx={{ color: 'primary.600', pb: 0.5, lineHeight: '20px', fontFamily: 'Lexend' }}>© 2023 General Folders</Typography>
    </Stack>
  </Stack>
);

export default Footer;
