import { uploadService } from '../services';
import { uploadConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';

function fileUpload(payload) {
  return (dispatch) => {
    dispatch(request());
    uploadService.fileUpload(payload)
    .then((res)=> {
      const { response, message, error } = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message));
      } else {
        dispatch(success(response)); 
        dispatch(snackbarActions(false, message));
      }
    })
  }
  function request() {
    return { type: uploadConstants.GET_UPLOAD };
  }
  function success(records) {
    return { type: uploadConstants.GET_UPLOAD_SUCCESS, records };
  }
  function failure(error,message) {
    return { type: uploadConstants.GET_UPLOAD_ERROR,error,message };
  }
}

function deleteUpload(payload) {
  return (dispatch) => {
    dispatch(request());
  }
  function request() {
    return { type: uploadConstants.DELETE_UPLOAD };
  }
}

function clear() {
  return (dispatch) => {
    dispatch(request());
  }
  function request() {
    return { type: uploadConstants.CLEAR };
  }
}

export const uploadActions = {
  fileUpload,
    deleteUpload,
    clear
};
