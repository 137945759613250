/*
 *
 * Scenario constants
 *
 */
const key = 'app/scenario/'
export const scenarioConstants = {
    GET_SCENARIOS : `${key}/GET_SCENARIOS`,
    GET_SCENARIOS_SUCCESS : `${key}/GET_SCENARIOS_SUCCESS`,
    GET_SCENARIOS_ERROR : `${key}/GET_SCENARIOS_ERROR`,
    
    GET_SCENARIO : `${key}/GET_SCENARIO`,
    GET_SCENARIO_SUCCESS : `${key}/GET_SCENARIO_SUCCESS`,
    GET_SCENARIO_ERROR : `${key}/GET_SCENARIO_ERROR`,

    SOURCE_DESTINATION_DATA : `${key}/SOURCE_DESTINATION_DATA`,
    SOURCE_DESTINATION_DATA_SUCCESS : `${key}/SOURCE_DESTINATION_DATA_SUCCESS`,
    SOURCE_DESTINATION_DATA_ERROR : `${key}/SOURCE_DESTINATION_DATA_ERROR`,

    LOAD_RECORDS : `${key}/LOAD_RECORDS`,
    LOAD_RECORDS_SUCCESS : `${key}/LOAD_RECORDS_SUCCESS`,
    LOAD_RECORDS_ERROR : `${key}/LOAD_RECORDS_ERROR`,

    LOAD_RECORD : `${key}/LOAD_RECORD`,
    LOAD_RECORD_SUCCESS : `${key}/LOAD_RECORD_SUCCESS`,
    LOAD_RECORD_ERROR : `${key}/LOAD_RECORD_ERROR`,

    DELETE_SCENARIO : `${key}/DELETE_SCENARIO`,
    DELETE_SCENARIO_SUCCESS : `${key}/DELETE_SCENARIO_SUCCESS`,
    DELETE_SCENARIO_ERROR : `${key}/DELETE_SCENARIO_ERROR`,
};





