/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { outBoundConstants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  loading: false,
  error: false, 
  success: false, 
  message: null,
  pageSize: 5,
  page: 0,
  total: null, 
  filters: null
};

export default function Reducer(state = initialState, props) {
  const { type, records,  error, message, page, total } = props;

  return produce(state, (draft) => {
    switch (type) { 
      case outBoundConstants.LOAD_RECORDS:
      case outBoundConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false; 
        draft.success = false;
        draft.message = '';
        break;
      case outBoundConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.pagination_id === v.pagination_id) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        break;
      case outBoundConstants.LOAD_RECORDS_ERROR:
      case outBoundConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      default:
        break;
    }
  });
}
