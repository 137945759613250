/*
 *
 * Counts reducer
 *
 */
import produce from 'immer';
import { dataCountConstants } from '../_constants';

const initialState = {
  loading: false, 
  error: false,
  message:'',
  counts:{}
};

/**
 * @param {object} state
 * @param {object} action
 */
const dataCountReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, counts } = action;
    switch (action.type) {
      case dataCountConstants.GET_DATACOUNT:
        draft.loading = true;    
        draft.error = false;    
        draft.message = '';    
        break;
      case dataCountConstants.GET_DATACOUNT_SUCCESS: 
        draft.loading = false;    
        draft.error = false;    
        draft.message = ''; 
        draft.counts = counts; 
        break;
      case dataCountConstants.GET_DATACOUNT_ERROR:
        draft.loading = false;    
        draft.error = error;    
        draft.message = message; 
        draft.counts = {};
        break;
      default:
        break;
    }
  });
export default dataCountReducer;
