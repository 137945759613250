import { useRef, useState} from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { 
  Box,  
} from '@mui/material'; 

// project-imports 
import IconButton from 'components/@extended/IconButton';  
// assets
import {  Notification} from 'iconsax-react';  


// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const NotificationPage = () => {
  const theme = useTheme(); 
  const anchorRef = useRef(null); 
  const [open, setOpen] = useState(false); 

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }; 

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';  
  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="large"
        sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
      >
          <Notification variant="Bold" color={"rgb(91, 107, 121)"}/>
      </IconButton> 
    </Box>
  );
};

export default NotificationPage;
