/*
 *
 * Client reducer
 *
 */
import produce from 'immer';
import { clientConstants } from '../_constants';

const initialState = {
  clientLoading: false, 
  error: false,
  message:'',
  counts:{},
  records:[],
  record:{},
  loading:false,
  pageSize: 5,
  page: 0,
  total: null, 
  filters: null
};

/**
 * @param {object} state
 * @param {object} action
 */
const clientReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, counts,page, total, records } = action;
    switch (action.type) {
      case clientConstants.CLIENT_DATA:
        draft.clientLoading = true;    
        draft.error = false;    
        draft.message = '';    
        break;
      case clientConstants.CLIENT_DATA_SUCCESS: 
        draft.clientLoading = false;    
        draft.error = false;    
        draft.message = message; 
        draft.counts = counts; 
        break;
      case clientConstants.CLIENT_DATA_ERROR:
        draft.clientLoading = false;    
        draft.error = error;    
        draft.message = message; 
        draft.counts = {};
        break;

        case clientConstants.LOAD_RECORDS:
      case clientConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false; 
        draft.success = false;
        draft.message = '';
        break;
      case clientConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.pagination_id === v.pagination_id) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = total;
        draft.page = page;
        break;
      case clientConstants.LOAD_RECORDS_ERROR:
      case clientConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case clientConstants.CLIENT_DELETE:
        draft.deleteloading = true;
        draft.success = false;
        draft.error = false;
        draft.message = message;
        break;
      case clientConstants.CLIENT_DELETE_SUCCESS:
        draft.deleteloading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case clientConstants.CLIENT_DELETE_ERROR:
        draft.deleteloading = false;
        draft.success = true;
        draft.error = error;
        draft.message = message;
        break
      default:
        break;
    }
  });
export default clientReducer;
