// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

import sendIcon   from "assets/icons/send_data.svg"
import requestIcon from "assets/icons/request_data.svg"


// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = { 
  type: 'group',
  divider:true,
  children: [
    {
      id: 'senddata',
      parent: '',
      icon: sendIcon,
      iconType:'img',
      title: <FormattedMessage id="senddata" />,
      type: 'item',
      url: '/senddata'
    },
    {
      id: 'requestdata',
      parent: 'actions',
      title: <FormattedMessage id="requestdata" />,
      type: 'item',
      icon: requestIcon,
      iconType:'img',
      url: '/requestdata'
    }
  ]
};

export default applications;
