import {  useMemo } from 'react';

// material-ui
import {  useTheme } from '@mui/material/styles';
import { AppBar, Divider, Toolbar, useMediaQuery } from '@mui/material';

// project-imports
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

//import IconButton from 'components/@extended/IconButton';
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'config';
import useConfig from 'hooks/useConfig';

// assets
//import { HambergerMenu } from 'iconsax-react';

// typs
import { LAYOUT_CONST } from 'types/config';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //
 

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  //const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  //const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';

  // common header
  const mainHeader = (
    <>
      <Toolbar sx={{ px: { xs: 2, sm: 2, lg: 1 } }}>
      {!isHorizontal ? (
         null
      ) : null}
      {headerContent} 
    </Toolbar>
    <Divider></Divider>
    </>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    elevation: 0,
    sx: {
      bgcolor: '#ffffff',
      backdropFilter: 'blur(8px)',
      zIndex: 1200,
      width: isHorizontal ? '100%' : open ? `calc(100% - ${DRAWER_WIDTH}px)` : { xs: '100%', lg: `calc(100% - ${MINI_DRAWER_WIDTH}px)` }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
