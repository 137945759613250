import { API } from 'aws-amplify';
import { getPdfParams } from 'helpers/tools';

function fileUpload(payload) {
  return API.post('gf-service', 'uploadtemp', getPdfParams( {body: payload}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const uploadService = {
  fileUpload
};