/*
 *
 * Scenario reducer
 *
 */
import produce from 'immer';
import { scenarioConstants } from '../_constants';

const initialState = {
  loading: false,
  jsonloading: false,
  scenarioloading: false,
  deleteloading: false,
  success: false,
  error: false,
  message: '',
  records: [],
  record: {},
  scenariorecords: [],
  pageSize: 5,
  page: 0,
  total: null, 
  filters: null
};

/**
 * @param {object} state
 * @param {object} action
 */
const scenarioReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, records, record,page, total } = action;
    switch (action.type) {
      case scenarioConstants.GET_SCENARIOS:
        draft.loading = true;
        draft.error = false;
        draft.message = '';
        break;
      case scenarioConstants.GET_SCENARIOS_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = '';
        draft.records = records;
        break;
      case scenarioConstants.GET_SCENARIOS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.records = [];
        break;
      case scenarioConstants.GET_SCENARIO:
        draft.error = false;
        draft.message = '';
        draft.jsonloading = true;
        break;
      case scenarioConstants.GET_SCENARIO_SUCCESS:
        draft.error = false;
        draft.message = '';
        draft.record = record;
        draft.jsonloading = false;
        break;
      case scenarioConstants.GET_SCENARIO_ERROR:
        draft.error = error;
        draft.message = message;
        draft.record = {};
        draft.jsonloading = false;
        break;
      case scenarioConstants.SOURCE_DESTINATION_DATA:
        draft.error = false;
        draft.message = '';
        draft.scenarioloading = true;
        break;
      case scenarioConstants.SOURCE_DESTINATION_DATA_SUCCESS:
        draft.error = false;
        draft.message = '';
        draft.scenariorecords = record;
        draft.scenarioloading = false;
        break;
      case scenarioConstants.SOURCE_DESTINATION_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        draft.scenariorecords = {};
        draft.scenarioloading = false;
        break;
      case scenarioConstants.LOAD_RECORDS:
      case scenarioConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case scenarioConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.pagination_id === v.pagination_id) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = total;
        draft.page = page;
        break;
      case scenarioConstants.LOAD_RECORDS_ERROR:
      case scenarioConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case scenarioConstants.DELETE_SCENARIO:
        draft.deleteloading = true;
        draft.success = false;
        draft.error = false;
        draft.message = message;
        break;
      case scenarioConstants.DELETE_SCENARIO_SUCCESS:
        draft.deleteloading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case scenarioConstants.DELETE_SCENARIO_ERROR:
        draft.deleteloading = false;
        draft.success = true;
        draft.error = error;
        draft.message = message;
        break;
      default:
        break;
    }
  });
export default scenarioReducer;
