import { countService } from '../services';
import { dataCountConstants } from '../_constants';

function getMegaMenuCounts() {
  return (dispatch) => {
    dispatch(request());
    countService.getMegaMenuCounts()
    .then((res)=> {
      const { response,message } = res;
      if (response.error) {
        dispatch(failure(true, message))
      } else {
        dispatch(success(response)); 
      }
    })
  }
  function request() {
    return { type: dataCountConstants.GET_DATACOUNT };
  }
  function success(counts) {
    return { type: dataCountConstants.GET_DATACOUNT_SUCCESS, counts };
  }
  function failure(error,message) {
    return { type: dataCountConstants.GET_DATACOUNT_ERROR,error,message };
  }
}

export const countActions = {
    getMegaMenuCounts
};
